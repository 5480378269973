import React, { useState } from "react";
import { useAuth } from "../auth/provider/AuthProvider";

const Login = () => {
  
  

  
  
  return (
    <>
      <div >
            
            <div className='container'>

                <br />
                <h2>Login</h2>
                <br />
                <form autoComplete="off" className='form-group'>
                    <label htmlFor="email">Email</label>
                    <input type="email" className='form-control' />
                    <br />
                    <label htmlFor="password">Password</label>
                    <input type="password" className='form-control' />
                    <br />
                    <button type="submit" className='btn btn-success btn-md mybtn'>LOGIN</button>
                </form>
            </div>
        </div>        
    </>
  );
  
}

export default Login;
