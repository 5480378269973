import React, { useState } from "react";

const TermsAndConditions = () => {
  
  

  
  
  return (
    <>
      <div >
            
            <div className='container'>

                <br />
                <h2>Terms and conditions</h2>
                <br />
                <p>Welcome to Mambo's Chicken and these are the terms and conditions
                    you are most likely not going to read. 
                    Still here? Very well then!
                </p>
                <p>
                    Please carefully read the following Terms and Conditions before using
                    our website or placing an order. By accessing or using our website, you agree to be bound by these
                    Terms and Conditions. If you do not agree with any part of these Terms and Conditions, 
                    please refrain from using our website.
                </p>
                <br/>
                
                <h4>General Information:</h4>
                <p>
                    a. Mambo's Chicken is a Southern African restaurant offering a variety of delicious meals.
                </p>
                <p>
                    b. Our website provides information about our menu, services, and allows customers to place orders 
                    for sit-in, take away, deliveries, and online ordering.
                </p>
                <p>
                    c. By placing an order through our website, you agree to pay for the items ordered and any applicable fees.
                </p>
                <br/>
                <h4>Online Ordering:</h4>
                <p>                
                    a. Our online ordering system allows you to browse our menu, 
                    customize your order, and choose your preferred method of service 
                    (sit-in, take away, or delivery).
                </p>
                <p>
                    b. Orders placed through the website are subject to availability and
                     confirmation.
                </p>
                    c. Payment for online orders can be made through the provided payment options.
                <p>
                    d. Once an order is confirmed, changes or cancellations may not be possible.
                     Please contact us directly for any modifications or refunds.
                </p>
                <br/>
                <h3>Delivery:</h3>
                <p>
                    a. We offer delivery services within the specified delivery areas.
                </p>
                <p>
                    b. Delivery times are estimated, and we will strive to deliver your order within the given timeframe. However, unforeseen circumstances may cause delays, and we appreciate your understanding in such situations.
                </p>
                <p>
                    c. Delivery fees may apply, and these will be communicated during the ordering process.
                </p>
                <br/>
                <h3>Sit-In and Take Away:</h3>
                <p>
                    a. We welcome customers to dine in at our restaurant or opt for take-away services.
                </p>
                <p>
                    b. For sit-in customers, please note that seating availability may be limited during peak hours, and we operate on a first-come, first-served basis.
                </p>
                <p>
                    c. Take-away orders will be prepared as promptly as possible, but please allow for some waiting time during busy periods
                </p>
                <br/>

                <h3>Pricing and Payments:</h3>
                <p>               
                    a. All prices listed on our website are in the local currency and include applicable taxes unless otherwise stated.
                </p>
                <p>
                    b. We reserve the right to modify prices, add or remove items from the menu, and change the availability of any service without prior notice.
                </p>
                <p>
                    c. Payment options will be provided during the online ordering process. We accept various forms of electronic payment.
                </p>

                <h3>Intellectual Property:</h3>
                <p>
                    a. The content on our website, including text, images, logos, and trademarks, is protected by copyright and other intellectual property laws.
                </p>
                <p>
                   b. You may not use, reproduce, modify, or distribute any content from our website without obtaining prior written permission from Mambo's Chicken.
                </p>
                <br/>
                <h3>Limitation of Liability:</h3>
                <p>
                    a. Mambo's Chicken will not be held responsible for any damages, losses, or injuries arising from the use of our website, services, or consumption of our food.
                </p>
                <p>
                    b. We strive to provide accurate and up-to-date information on our website, but we do not warrant the completeness, accuracy, or reliability of any content.
                </p>
                <br/>
                <h3>Governing Law:</h3>
                <p>
                    a. These Terms and Conditions shall be governed by and construed in accordance with the laws of the jurisdiction in which Mambo's Chicken operates.
                </p>
                <br/>
                <p>
                    Please note that these Terms and Conditions may be subject to change without prior notice. It is your responsibility to review this page periodically for any updates. If you have any questions or concerns regarding these Terms and Conditions, please contact us directly.
                </p>
                
                <p>
                    Thank you for choosing Mambo's Chicken. Eat Like A King!
                </p>


            </div>
        </div>        
    </>
  );
  
}

export default TermsAndConditions;
