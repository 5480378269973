import React from "react";
import {useSelector} from "react-redux";
import {BannerStrip} from "../layout/widgets/banners/BannerStrip";
import {MenuFoodItems} from "../layout/widgets/menu/MenuFoodItems";
import {MenuNavBar} from "../layout/widgets/menu/MenuNavBar";
import {MenuSlider} from "../layout/widgets/sliders/MenuSlider";

type PageProps = {
  children?: React.ReactNode;
}

const Contacts:React.FunctionComponent<PageProps> = ({ children, ...props}) : any | null => {
    
    // @ts-ignore
   // const menuCategories = useSelector(state => state.menu.value);
    
  return (
    <>    
        <div >
            
            <div style={{textAlign: 'center'}}>
              <a id="download-btn" href="MambosMenu.pdf" download>Download Menu</a>
            </div>
            <img src="/assets/elements/menu/MenuItems1.png" width="100%"></img>
            <img src="/assets/elements/menu/MenuItems2.png" width="100%"></img>
            {/*<object data="MambosMenu.pdf" width="100%" height="900px">
                <p>Unable to display PDF file. <a href="MambosMenu.pdf">Download</a> instead.</p>
  </object>  */}        
            
        </div>        
  </>
  );
  
};

export default Contacts;