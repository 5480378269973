import React, { useState } from "react";

const Downloads = () => {
  
  

  
  
  return (
    <>
      <div >
            
            <div className='container'>

                <br />
                <h2>Hollup! We are still cooking...</h2>
                <br />
                <br/>
                
            </div>
        </div>        
    </>
  );
  
}

export default Downloads;
